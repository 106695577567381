import React, {useState} from "react";
import {Link} from "react-router-dom";
import altLogo from "../../images/logos/new-white-logo.png";
import backgroundVideo from "../../video/wonder-video.mp4";
import {useSelector} from "react-redux";
import {get} from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default function Header(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  const [navOpen, setNavOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const componentLoading = useSelector((state) =>
    get(state, "prismic.header.loading", true)
  );
  const component = useSelector((state) =>
    get(state, "prismic.header.data", false)
  );

  const logo = get(component, "logo.url", false);
  const navigationLinks = get(component, "navigation", false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderNavigation = (navigationLinks) => {
    const excludePages = isMobile
      ? []
      : ["Buy Online", "Store Locator", "Find Us"];

    return navigationLinks.map((el, i) => {
      if (!excludePages.find((e) => e === el.link_text[0].text)) {
        return (
          <Link
            onClick={() => {
              setNavOpen(false);
            }}
            key={i}
            to={`/${el.link_url.slug}`}
            className={
              props.activePage === `/${el.link_url.slug}` ? "active" : undefined
            }
          >
            {el.link_text[0].text}
          </Link>
        );
      }
    });
  };

  const renderShopButton = () => {
    return (
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={"shop-dropdown"}
        >
          Shop
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Link to={"/shop"}>Buy Online</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to={"/find-us"}>Store Locator</Link>
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    !componentLoading &&
    component && (
      <header>
        <div className="wrapper">
          <Link
            to="/"
            className={`logo-container ${navOpen ? "hide" : undefined}`}
          >
            <img id="logo" className="logo" src={logo} alt="Brand Logo" />
          </Link>
          <div
            className={`nav-menu ${navOpen ? "close" : "open"}`}
            onClick={toggleNav}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <nav
            id="navigation-container"
            className={navOpen ? "open" : undefined}
          >
            <div className="background-video">
              <video autoPlay playsInline muted loop>
                <source src={backgroundVideo} type="video/mp4" />
              </video>
            </div>
            <Link
              className="hide-desktop"
              to="/"
              onClick={() => {
                setNavOpen(false);
              }}
            >
              <img
                className="mobile-logo"
                src={altLogo}
                alt="Mobile Brand Logo"
              />
            </Link>

            {renderNavigation(navigationLinks)}
            {!isMobile && renderShopButton()}
          </nav>
        </div>
      </header>
    )
  );
}
