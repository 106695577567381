export default function makeLowercaseKeys(arr) {
  const newarr = [];

  arr.map((obj, i) => {
    var key,
      keys = Object.keys(obj);
    var n = keys.length;
    var newobj = {};
    while (n--) {
      key = keys[n];
      newobj[key.toLowerCase()] = obj[key];
    }
    newarr.push(newobj);
    return null;
  });
  return newarr;
}
