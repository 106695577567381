import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

export default function MarketingDialog(props) {
  const [open, setOpen] = useState(true);

  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [emailAddress, setEmailAddress] = useState(false);

  const [errors, setErrors] = useState(false);

  const handleClose = () => {
    setOpen(false);
    props.closeDialog();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = { firstName, lastName, emailAddress };
    const errors = {};
    const requiredFields = ["firstName", "lastName", "emailAddress"];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = "Required";
      }
    });
    if (
      values[emailAddress] &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[emailAddress])
    ) {
      errors.emailAddress = "Invalid email address";
    }

    if (errors.length > 0) {
      console.log(errors);
      setErrors(errors);
    } else {
      console.log("YAY");
      window.analytics.track("Email Marketing Sign Up", values);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="marketing-dialog-wrapper">
          <h2>Stay in the Loop</h2>
          <p>
            Sign up for our newsletter to stay posted on product news and much
            more! We’ll just need a little information from you.
          </p>
          <form onSubmit={handleSubmit}>
            <TextField
              margin="dense"
              id="firstName"
              label="First Name"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setFirstName({ firstName: e.target.value });
              }}
            />
            <TextField
              margin="dense"
              id="lastName"
              label="Last Name"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setLastName({ lastName: e.target.value });
              }}
            />
            <TextField
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setEmailAddress({ emailAddress: e.target.value });
              }}
            />

            <DialogActions>
              <Button type="button" className="button" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" className="button">
                Subscribe
              </Button>
            </DialogActions>
          </form>
        </div>
      </Dialog>
    </div>
  );
}
