import { select } from "redux-saga/effects";
import { get } from "lodash";
import { setupScripts } from "./scripts";

export default function* setUpAccessibility() {
  const accessibilityEnabled = yield select((state) =>
    get(state, "accessibility.show")
  );

  if (!accessibilityEnabled) {
    return;
  }

  yield setupScripts();
}
