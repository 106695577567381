import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {get} from "lodash";
import Hero from "../components/Hero";
import Slices from "../slices";
import {Redirect} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Cookie from "js-cookie";
import {DISCOVER_WONDER_PAGE} from "../constants/cookies";

export default function PrismicPage(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const loadingFunc = props.loading;
  const animation = props.animation;

  const isStaging = props.isStaging;

  const whichPage = (path) => {
    if (path === "/") {
      return "home";
    } else if (path === "/404") {
      return "404";
    } else {
      return path.substr(1);
    }
  };

  const pagePath = whichPage(location.pathname);

  const pageLoading = useSelector((state) =>
    get(state, "prismic.page.loading", true)
  );
  const page = useSelector((state) => get(state, "prismic.page.data", false));
  const globalSlicesLoading = useSelector((state) =>
    get(state, "prismic.global_slices.loading", true)
  );
  const globalSlices = useSelector((state) =>
    get(state, "prismic.global_slices.data.body", false)
  );

  const richContent = get(page, "hero_section[0].rich_content");
  const richContent2 = get(page, "hero_section[0].rich_content2");
  const buttons = get(page, "buttons");
  const ctaAlignment = get(page, "hero_section[0].cta_alignment");
  const gallery = get(page, "gallery");
  const backgroundColor = get(page, "hero_section[0].background_color");
  const backgroundImage = get(page, "hero_section[0].background_image.url");
  const backgroundVideo = get(page, "hero_section[0].background_video.url");

  const mobileBackgroundImage = get(
    page,
    "hero_section[0].mobile_background_image.url"
  );
  const showGlobalSlices = get(page, "global_slices");

  const disableHero = get(page, "disable_hero");

  const slices = get(page, "body");

  const openDiscoverPageDialog = () => {
    if (pagePath === "discover-some-wonder") {
      if (Cookie.get("wonder_over21") && !Cookie.get(DISCOVER_WONDER_PAGE))
        dispatch({
          type: "DIALOG/SHOW",
          id: "discover_wonder_page_dialog",
        });
    } else return null;
  };

  useEffect(() => {
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_BY_UID",
      key: "page",
      id: pagePath,
    });
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_PAGE",
      key: "global_slices",
    });
  }, [dispatch, pagePath]);

  useEffect(() => {
    if (!pageLoading) {
      loadingFunc("yas");
    }
  }, [pagePath, pageLoading, loadingFunc]);

  if (pageLoading) {
    return (
      <CircularProgress
        style={{
          position: "fixed",
          color: "black",
          top: "calc(50% - 25px)",
          left: "calc(50% - 25px)",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  if (!page && !pageLoading && !isStaging) {
    return <Redirect to={"/404"} />;
  }
  return (
    !pageLoading &&
    page && (
      <div className={`animation ${animation}`}>
        {openDiscoverPageDialog()}
        {!disableHero && (
          <Hero
            richContent={richContent}
            richContent2={richContent2}
            backgroundColor={backgroundColor}
            buttons={buttons}
            ctaAlignment={ctaAlignment}
            gallery={gallery}
            backgroundImage={backgroundImage}
            mobileBackgroundImage={mobileBackgroundImage}
            backgroundVideo={backgroundVideo}
          />
        )}

        <Slices slices={slices}></Slices>
        {showGlobalSlices && !globalSlicesLoading && globalSlices && (
          <Slices slices={globalSlices}></Slices>
        )}
      </div>
    )
  );
}
