import React from "react";
import PrismicPage from "./pages/PrismicPage";
import ShopPage from "./pages/ShopPage";
import StoreLocatorPage from "./pages/StoreLocatorPage";

import {Switch, Route, Redirect, useLocation} from "react-router-dom";

export default function Routes({loading, animation, isStaging}) {
  const {pathname} = useLocation();
  const prismicPage = () => {
    return (
      <PrismicPage
        loading={loading}
        animation={animation}
        isStaging={isStaging}
      />
    );
  };

  const shopPage = () => {
    return <ShopPage loading={loading} animation={animation} />;
  };

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route
        path="/gummies-giveaway-04-21"
        component={() => {
          window.location.href = "https://www.instagram.com/p/CPGLRmaBRTB/";
          return null;
        }}
      />
      <Route path="/find-us">
        <Redirect to="/store-locator" />
      </Route>
      <Route exact path="/store-locator" component={StoreLocatorPage} />
      <Route exact path="/shop" render={shopPage} />
      <Route exact path="/contact">
        <Redirect to="/404" />
      </Route>
      <Route path="/:id" render={prismicPage} />
      <Route path="/404" render={prismicPage} />
      <Route exact path="/" render={prismicPage} />
    </Switch>
  );
}
