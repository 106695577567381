import { put, call } from 'redux-saga/effects';
import {
  fetchSinglePage,
  fetchSingleByUID,
  fetchRepeatable
} from '../../prismic/fetch-prismic-data';

export function* prismicFetch(fn, action) {
  const reduxKey = action.reduxKey || action.key;
  try {
    yield put({ type: 'PRISMIC_FETCHING', key: reduxKey });
    const data = yield fn();

    yield put({
      type: 'PRISMIC_FETCH_SUCCESS',
      data: data,
      key: reduxKey
    });
  } catch (err) {
    console.error(err); //eslint-disable-line
    return yield put({
      type: 'PRISMIC_FETCH_ERROR',
      key: reduxKey,
      error: err
    });
  }
}

export function* loadSinglePage(api, router, action) {
  const fn = function*() {
    const data = yield call(fetchSinglePage, action.key, action.tags);
    return data.data;
  };
  // yield delay(3000);
 
  yield prismicFetch(fn, action);
}

export function* loadSingleByUID(api, router, action) {
  const fn = function*() {
    const data = yield call(fetchSingleByUID, action.key, action.id);

    if(data === undefined) {
      return false;
    }
    else {
      return data.data
    }
  };

  yield prismicFetch(fn, action);
}

export function* loadRepeatable(api, router, action) {
  const fn = function*() {
    const data = yield call(fetchRepeatable, action.key, action.max);
    return data;
  };

  yield prismicFetch(fn, action);
}
