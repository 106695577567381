import React, {useState} from "react";
import PrismicRichText from "../components/PrismicRichText";

export default function FormsSection(slice) {
  const [selectedForm, setSelectedForm] = useState(
    slice.items[0].title[0].text
  );

  const [selectedFormBgColor, setSelectedFormBgColor] = useState(
    slice.items[0].background_color
  );

  const [selectedFormTextColor, setSelectedFormTextColor] = useState(
    slice.items[0].text_color
  );

  const bgStyle = {
    backgroundColor: selectedFormBgColor,
    color: selectedFormTextColor,
  };

  const sectionAlignment = slice.primary.section_alignment;

  const renderPills = (items) => {
    return items.map((el, i) => (
      <div
        onClick={() => {
          setSelectedForm(el.title[0].text);
          setSelectedFormBgColor(el.background_color);
          setSelectedFormTextColor(el.text_color);
        }}
        key={i}
        className={`button outlined ${
          selectedForm === el.title[0].text ? "active" : false
        }`}
      >
        {el.title[0].text}
      </div>
    ));
  };

  const renderImages = (items) => {
    return items.map((el, i) => (
      <div
        key={i}
        className={`form-image ${
          selectedForm === el.title[0].text ? "visible" : false
        }`}
      >
        <img src={el.image.url} alt="Products" />
      </div>
    ));
  };

  const renderContent = (items) => {
    return items.map((el, i) => (
      <div
        key={i}
        className={`form-content ${
          selectedForm === el.title[0].text ? "visible" : false
        }`}
      >
        <PrismicRichText render={el.content} />
      </div>
    ));
  };

  return (
    <section className="forms-section standard-padding" style={bgStyle}>
      <div className="wrapper">
        <div className="flex-wrapper">
          <div className="flex-child content-wrapper">
            <div className="text-content">
              <PrismicRichText render={slice.primary.content} />
            </div>
            <div className="pills">{renderPills(slice.items)}</div>
          </div>
          <div className="flex-child image-wrapper">
            <div className="content">{renderContent(slice.items)}</div>
            <div
              className={`form-images ${
                sectionAlignment === "Image / Content" && "lead-image"
              }`}
            >
              {renderImages(slice.items)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
