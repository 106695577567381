import React, { Component } from "react";
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";

class ProviderWithRouter extends Component {
  constructor(props) {
    super(props);
    this.store = configureStore();
  }

  render() {
    return <Provider store={this.store}>{this.props.children}</Provider>;
  }
}

export default ProviderWithRouter;
