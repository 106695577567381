import { put } from "redux-saga/effects";
import {
  CCPA_ANALYTICS,
  CCPA_MARKETING,
  CCPA_PERSONALIZATION,
} from "./../../constants/localStorage";

export function* checkCompliance(api, router, action) {
  const allowAnalytics = window.localStorage.getItem(CCPA_ANALYTICS);
  const allowMarketing = window.localStorage.getItem(CCPA_MARKETING);
  const allowPersonalization =
    window.localStorage.getItem(CCPA_PERSONALIZATION);

  if (allowAnalytics !== null) {
    yield put({
      type: "COOKIE_COMPLIANCE/SET",
      analytics: allowAnalytics === "true",
      personalization: allowPersonalization === "true",
      marketing: allowMarketing === "true",
    });
    yield put({ type: "COOKIE_COMPLIANCE/CHECK_FINISHED" });
  } else {
    yield put({ type: "COOKIE_COMPLIANCE/SHOW_SIMPLE", needsToAccept: true });
  }
}

export function* saveSettings(api, router, action) {
  yield put({
    type: "COOKIE_COMPLIANCE/SET",
    analytics: action.analytics,
    personalization: action.personalization,
    marketing: action.marketing,
  });

  window.localStorage.setItem(CCPA_ANALYTICS, action.analytics);
  window.localStorage.setItem(CCPA_MARKETING, action.marketing);
  window.localStorage.setItem(CCPA_PERSONALIZATION, action.personalization);

  yield put({
    type: `COOKIE_COMPLIANCE/SAVED/ANALYTICS/${
      action.analytics ? "ENABLED" : "DISABLED"
    }`,
  });
  yield put({
    type: `COOKIE_COMPLIANCE/SAVED/MARKETING/${
      action.marketing ? "ENABLED" : "DISABLED"
    }`,
  });
  yield put({
    type: `COOKIE_COMPLIANCE/SAVED/PERSONALIZATION/${
      action.personalization ? "ENABLED" : "DISABLED"
    }`,
  });

  yield put({
    type: "COOKIE_COMPLIANCE/CHECK_FINISHED",
    personalization: action.personalization,
    marketing: action.marketing,
    analytics: action.analytics,
  });
  yield put({ type: "COOKIE_COMPLIANCE/HIDE" });
}
