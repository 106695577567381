import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import CustomCarousel from "../components/Carousel";
import Link from "../components/Link";
import {get} from "lodash";

export default function RenderProductsSection(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  const backgroundStyle = {
    background: slice.primary.background_color,
    color: slice.primary.text_color,
  };

  const renderProductsList = (items) => {
    return items.map((el, i) => {
      const ctaText = el.cta_text;
      const ctaLink = el.cta_link;
      const richContent = el.rich_content;

      const spaceOut = el.space_out;
      const backgroundColor = el.background_color;

      const style = {
        backgroundColor: backgroundColor,
      };

      return (
        <div
          key={i}
          className={`product ${spaceOut && "extra-padding"} ${
            backgroundColor && "solid-background"
          }`}
          style={style}
        >
          <div className="image">
            <img src={el.image.url} alt="Products" />
          </div>
          <div className="rich-text center-align">
            <PrismicRichText render={richContent} />
          </div>

          <div className="content center-align">
            <Link
              to={"/" + get(el, "cta_link.uid")}
              url={get(el, "cta_link.url")}
              target={get(el, "cta_link.target")}
              className="button"
            >
              {ctaText[0].text}
            </Link>
          </div>
        </div>
      );
    });
  };
  return (
    <section
      className="product-section standard-padding gray-headlines-and-copy"
      style={backgroundStyle}
    >
      <div className="wrapper">
        <div className="content-wrapper">
          <PrismicRichText render={slice.primary.content} />

          <div className={`button-wrapper ${slice.primary.cta_alignment}`}>
            <Link
              to={"/" + get(slice, "primary.cta_link.uid")}
              url={get(slice, "primary.cta_link.url")}
              target={get(slice, "primary.cta_link.target")}
              className={`button solid-black`}
            >
              {slice.primary.cta_text[0].text}
            </Link>
          </div>
        </div>

        {isMobile ? (
          <div className="hero-carousel hide-thumbs hide-indicators animate">
            <CustomCarousel items={slice.items} indicators={false} />
          </div>
        ) : (
          <div className="products-wrapper animate custom-animation">
            {renderProductsList(slice.items)}
          </div>
        )}
      </div>
    </section>
  );
}
