import {mainUserWayScript} from "./accessibilityCode";

export function setupScripts() {
  setupAccessibility();
}

function setupAccessibility() {
  var head = document.getElementsByTagName("head")[0];
  var accessibilityScript = document.createElement("script");
  var inlineScript = document.createTextNode(mainUserWayScript);
  accessibilityScript.appendChild(inlineScript);
  head.appendChild(accessibilityScript);
}
