import {get} from "lodash";

const selector = (prefix) => (state) => get(state, prefix);

export const dialog = {
  id: selector("dialog.id"),
  open: selector("dialog.open"),
  options: selector("dialog.options"),
  data: selector("dialog.data"),
};
