import { select } from "redux-saga/effects";
import { get } from "lodash";
import { setupScripts } from "./scripts";

export default function* complianceFinished() {
  const allowAnalytics = yield select((state) =>
    get(state, "cookieCompliance.analytics")
  );

  if (!allowAnalytics) {
    return;
  }

  yield setupScripts();
}
