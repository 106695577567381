import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import linkResolver from "../helpers/linkResolver";
import {get, isEmpty} from "lodash";
import Link from "../components/Link";

export default function TextImageSectionTwoCopySections(slice) {
  const backgroundStyle = {
    background: get(slice, "primary.background_color"),
    color: get(slice, "primary.text_color"),
  };

  const sectionAlignment = get(slice, "primary.section_alignment");
  const fullWidth = get(slice, "primary.full_width");
  const content1 = get(slice, "primary.content_1");
  const content2 = get(slice, "primary.content_2");
  const firstLink1Text = get(slice, "primary.first_link_1_text");
  const firstLink1URL = get(slice, "primary.first_link_1_url");
  const secondLink1Text = get(slice, "primary.second_link_1_text");
  const secondLink1URL = get(slice, "primary.second_link_1_url");

  const firstLink2Text = get(slice, "primary.first_link_2_text");
  const firstLink2URL = get(slice, "primary.first_link_2_url");
  const secondLink2Text = get(slice, "primary.second_link_2_text");
  const secondLink2URL = get(slice, "primary.second_link_2_url");

  return (
    <section
      className="text-image-section two-copy-section gray-headlines-white-button-copy animate custom-animation"
      style={backgroundStyle}
    >
      <div className={`wrapper ${fullWidth === "Yes" && "full-width"}`}>
        <div
          className={`flex-wrapper ${
            sectionAlignment === "Image / Content" && "lead-image"
          }`}
        >
          <div className="content-wrapper standard-padding">
            <div className="content-wrapper-inner">
              {/* SECTION 1 START */}
              <div className="section1">
                <PrismicRichText render={content1} />
                {!isEmpty(firstLink1Text) && (
                  <div
                    className={`button-wrapper ${slice.primary.cta_alignment}`}
                  >
                    <Link
                      to={firstLink1URL?.uid}
                      url={firstLink1URL?.url}
                      target={firstLink1URL?.target}
                      className="button solid-black"
                    >
                      {<PrismicRichText render={firstLink1Text} />}
                    </Link>
                  </div>
                )}
                {!isEmpty(secondLink1Text) && (
                  <div
                    className={`button-wrapper ${slice.primary.cta_alignment}`}
                  >
                    <Link
                      to={secondLink1URL?.uid}
                      url={secondLink1URL?.url}
                      target={secondLink1URL?.target}
                      className="button solid-black"
                    >
                      {<PrismicRichText render={secondLink1Text} />}
                    </Link>
                  </div>
                )}
              </div>
              {/* SECTION 2 START */}
              <div className="section2">
                <PrismicRichText render={content2} />
                {!isEmpty(firstLink2Text) && (
                  <div
                    className={`button-wrapper ${slice.primary.cta_alignment}`}
                  >
                    <Link
                      to={firstLink2URL?.uid}
                      url={firstLink2URL?.url}
                      target={firstLink2URL?.target}
                      className="button solid-black"
                    >
                      {<PrismicRichText render={firstLink2Text} />}
                    </Link>
                  </div>
                )}
                {!isEmpty(secondLink2Text) && (
                  <div
                    className={`button-wrapper ${slice.primary.cta_alignment}`}
                  >
                    <Link
                      to={secondLink2URL?.uid}
                      url={secondLink2URL?.url}
                      target={secondLink2URL?.target}
                      className="button solid-black"
                    >
                      {<PrismicRichText render={secondLink2Text} />}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>

          {slice.primary.image.url && (
            <div className="image-wrapper">
              <img src={get(slice, "primary.image.url")} alt="Featured" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
