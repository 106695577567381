import React from "react";
import {useSelector} from "react-redux";
import * as selector from "../../redux/selectors";

const withDialogManager = (dialogId, WrappedDialog) => {
  return function (props) {
    const open = useSelector(selector.dialog.open);
    const openDialogId = useSelector(selector.dialog.id);

    if (!open || dialogId !== openDialogId) return null;

    return <WrappedDialog {...props} />;
  };
};

export default withDialogManager;
