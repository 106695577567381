import React from "react";
import {useSelector} from "react-redux";
import {get} from "lodash";
import linkResolver from "./../../helpers/linkResolver";
import AccessibilitySwitch from "../AccessibilitySwitch";
import CookieSettingsLink from "./CookieSettingsLink";
import PrismicRichText from "../PrismicRichText";
import Link from "../Link";

export default function Footer() {
  const componentLoading = useSelector((state) =>
    get(state, "prismic.footer.loading", true)
  );
  const component = useSelector((state) =>
    get(state, "prismic.footer.data", false)
  );

  const backgroundColor = get(component, "footer_background_color");
  const textColor = get(component, "footer_text_color");

  const backgroundStyle = {
    color: textColor,
    background: backgroundColor,
  };

  const year = new Date().getFullYear();

  const renderFooterColumns = (columns) => {
    return columns.map((el, i) => (
      <div key={i} className="footer-section">
        <div className="heading">
          <PrismicRichText
            linkResolver={linkResolver}
            render={el.column_title}
          />
        </div>
        <div className="content">
          <PrismicRichText linkResolver={linkResolver} render={el.content} />
        </div>
      </div>
    ));
  };

  const renderSocialLinks = (socialLinks) => {
    return socialLinks.map((el, i) => (
      <Link
        key={i}
        url={get(el, "link.url")}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={get(el, "icon.url")} alt="Social Icon"></img>
      </Link>
    ));
  };

  return (
    !componentLoading &&
    component && (
      <footer className="standard-padding" style={backgroundStyle}>
        <div className="wrapper">
          <div className="footer-section">
            <p>
              <span className="mr-10">Grown by </span>
              <span className="logo-text">CRESCO LABS</span>
            </p>
            <p>
              We’re on a mission to normalize, professionalize, and
              revolutionize cannabis.
            </p>
          </div>
          {renderFooterColumns(component.footer)}
          <div className="footer-section">
            <div className="cookie-settings">
              <CookieSettingsLink />
            </div>
            <div className="social">
              <p className="copyright">
                © {year} Wonder Wellness Co. All rights reserved.
              </p>
              {renderSocialLinks(component.social_links)}
              <div className="accessibility">
                <AccessibilitySwitch />
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  );
}
