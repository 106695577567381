import React from "react";
import {get} from "lodash";

export default function IframeSlice(slice) {
  const iframeSrc = get(slice, "primary.iframe_url.url");

  return (
    <div className="simple-page" style={{padding: "0px"}}>
      <div className="extra-margin"></div>
      <div className="sl-iframe-container">
        <iframe src={iframeSrc}></iframe>
      </div>
    </div>
  );
}
