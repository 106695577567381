import React from "react";
import { Button } from "@material-ui/core";
import { get } from "lodash";

export default function PDFSection(slice) {
  const backgroundStyle = {
    background: get(slice, "primary.background_color"),
    textAlign: "center",
  };

  const pdfFile = get(slice, "primary.pdf_link.url");

  return (
    <section className="pdf-section standard-padding" style={backgroundStyle}>
      <div className="wrapper">
        <Button
          target="_blank"
          color="primary"
          variant="contained"
          href={pdfFile}
        >
          View Spanish Product Labels
        </Button>
      </div>
    </section>
  );
}
