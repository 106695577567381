import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import Grid from "@material-ui/core/Grid";
import isValidLink from "../helpers/isValidLink";
import {get} from "lodash";
import Link from "../components/Link";

export default function ColumnSlice(slice) {
  const backgroundStyle = {
    backgroundColor: get(slice.primary, "background_color"),
    color: get(slice.primary, "text_color"),
  };

  const renderColumns = (items) => {
    return items.map((el, i) => {
      const ctaText = el.cta_text;
      const ctaLink = el.cta_link;
      const bgStyle = {
        backgroundImage: `url(${el.background_image.url})`,
        padding: el.background_image.url ? "50px 20px" : "20px",
      };
      return (
        <Grid item key={i} className="column" xs={12} sm style={bgStyle}>
          {el.foreground_image.url && (
            <div className="foreground-image">
              <img src={el.foreground_image.url} alt="Hero Element" />
            </div>
          )}

          <div
            className="content"
            style={{textAlign: "center", color: "white"}}
          >
            <PrismicRichText render={el.content} />
          </div>
          {isValidLink(ctaLink, ctaText) && (
            <div className="buttons">
              <Link
                to={"/" + get(el, "cta_link.uid")}
                url={get(el, "cta_link.url")}
                target={get(el, "cta_link.target")}
                className="button"
              >
                {ctaText[0].text}
              </Link>
            </div>
          )}
        </Grid>
      );
    });
  };
  return (
    <section
      className="column-section standard-padding"
      style={backgroundStyle}
    >
      <div className="wrapper">
        <div className="content">
          <PrismicRichText render={slice.primary.content} />
        </div>
        <Grid container className="columns">
          {renderColumns(slice.items)}
        </Grid>
      </div>
    </section>
  );
}
