import React from "react";
import Link from "../components/Link";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import isValidLink from "../helpers/isValidLink";
import PrismicRichText from "../components/PrismicRichText";
import {get} from "lodash";

export default function RenderTextSection(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("600"));

  const bgImage = isMobile
    ? slice.primary.mobile_background_image.url
    : slice.primary.desktop_background_image.url;

  const backgroundStyle = {
    background: slice.primary.background_color,
    color: slice.primary.text_color,
  };

  const ctaText = slice.primary.cta_text;
  const ctaLink = slice.primary.cta_link;

  return (
    <section
      className="text-section standard-padding gray-headlines-and-copy"
      style={backgroundStyle}
    >
      <div className="wrapper">
        <PrismicRichText render={slice.primary.content} />
        {isValidLink(ctaLink, ctaText) && (
          <div className={`button-wrapper ${slice.primary.cta_alignment}`}>
            <Link
              to={"/" + get(slice, "primary.cta_link.uid")}
              url={get(slice, "primary.cta_link.url")}
              target={get(slice, "primary.cta_link.target")}
              className={`button solid-black`}
            >
              {slice.primary.cta_text[0].text}
            </Link>
          </div>
        )}

        {bgImage && (
          <div className="image-wrapper animate">
            <img src={bgImage} alt="background" />
          </div>
        )}
      </div>
    </section>
  );
}
