import Prismic from 'prismic-javascript';
// -- Prismic API endpoint
const apiEndpoint = 'https://wonder-staging.cdn.prismic.io/api/v2';
// -- Access Token if the repository is not public
const accessToken =
  'MC5YeEhZZkJFQUFDSUF3QjRZ.77-9OS7vv71pWjxVAO-_vTFK77-9Mu-_vUjvv73vv73vv73vv71i77-977-977-977-9Je-_ve-_ve-_ve-_ve-_ve-_vQ';
// -- Prismic Client
const PrismicClient = Prismic.client(apiEndpoint, { accessToken });

export default PrismicClient;
