import {all, takeEvery, takeLatest} from "redux-saga/effects";

import {loadSinglePage, loadSingleByUID, loadRepeatable} from "./prismic";
import {checkCompliance, saveSettings} from "./cookie_compliance";
import {initialAppLoad} from "./loading";
import complianceFinished from "./analytics";
import {checkAccessibility, saveAccessibility} from "./accessibility";
import setUpAccessibility from "./accessibilityScript";

const defaultSaga = function* (api, router) {
  yield all([
    takeEvery("PRISMIC_LOAD_SINGLE_PAGE", loadSinglePage, api, router),
    takeEvery("PRISMIC_LOAD_SINGLE_BY_UID", loadSingleByUID, api, router),
    takeEvery("PRISMIC_LOAD_REPEATABLE", loadRepeatable, api, router),
    takeLatest("COOKIE_COMPLIANCE/CHECK_FINISHED", complianceFinished),
    takeLatest("COOKIE_COMPLIANCE/CHECK", checkCompliance, api, router),
    takeLatest("COOKIE_COMPLIANCE/SAVE", saveSettings, api, router),
    takeLatest("ACCESSIBILITY/CHECK", checkAccessibility, api, router),
    takeLatest("ACCESSIBILITY/SAVE", saveAccessibility, api, router),
    takeLatest("ACCESSIBILITY/ADD", setUpAccessibility),
    takeEvery("INITIALIZE_APP", initialAppLoad, api, router),
  ]);
};

export default defaultSaga;
