const defaultState = {
  show: false,
};

const handlers = {
  "ACCESSIBILITY/SET": (state, action) => ({
    ...state,
    show: action.show,
  }),
};

export default function reducer(state = defaultState, action) {
  if (!handlers[action.type]) {
    return state;
  }
  return handlers[action.type](state, action);
}
