import React from "react";
import Grid from "@material-ui/core/Grid";
import CustomCarousel from "../components/Carousel";
import Link from "../components/Link";
import isValidLink from "../helpers/isValidLink";
import PrismicRichText from "../components/PrismicRichText";
import {get} from "lodash";

export default function ProductGallery(slice) {
  const backgroundStyle = {
    background: slice.primary.background_color,
  };

  const ctaText = slice.primary.cta_text;
  const ctaLink = slice.primary.cta_link;

  const renderCarousel = (items) => {
    return (
      <div className="hero-carousel hide-indicators">
        <CustomCarousel items={items} indicators={false} thumbs={true} />
      </div>
    );
  };
  return (
    <section
      className="product-gallery-section gray-headlines-and-copy"
      style={backgroundStyle}
    >
      <Grid container className="wrapper">
        <Grid item xs={12} md={6} className="carousel-wrapper animate">
          {renderCarousel(slice.items)}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="text-content standard-padding animate custom-animation"
        >
          <PrismicRichText render={slice.primary.content} />

          {isValidLink(ctaLink, ctaText) && (
            <div className="buttons">
              <Link
                to={"/" + get(slice, "primary.cta_link.uid")}
                url={get(slice, "primary.cta_link.url")}
                target={get(slice, "primary.cta_link.target")}
                className="button"
              >
                {ctaText[0].text}
              </Link>
            </div>
          )}
        </Grid>
      </Grid>
    </section>
  );
}
