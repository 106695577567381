import React, {useState} from "react";
import {DialogContent} from "@material-ui/core";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import {useDispatch} from "react-redux";
import styled from "styled-components";
import withDialogManager from "./withDialogManager";
import Cookie from "js-cookie";
import {DISCOVER_WONDER_PAGE} from "../../constants/cookies";
import {Button} from "@material-ui/core";

const StlDialogContent = styled(DialogContent)`
  padding: 24px 30px 8px;
  text-align: center;
  @media only screen and (max-width: 600px) {
    padding: 24px 16px 8px;
  }
`;

const StlFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 0.9rem;
    margin: 5px 5px 5px 0;
    text-align: left;
    display: flex;
    justify-content: flex-start;
  }
`;

const StlButtonWrapper = styled.div`
  gap: 20px;
  justify-content: center;
  @media only screen and (min-width: 600px) {
    flex-wrap: nowrap;
    margin-top: 25px !important;
  }
`;

function DiscoverWonderPageDialog() {
  const dispatch = useDispatch();
  const [dialogCookie, setDialogCookie] = useState(false);

  const closeDialog = () => {
    dispatch({type: "DIALOG/CLOSE"});
  };

  const cancelButtonClick = () => {
    if (dialogCookie) {
      Cookie.set(DISCOVER_WONDER_PAGE, true, {expires: 30});
    }
    closeDialog();
  };

  const backToHomepage = () => {
    if (dialogCookie) {
      Cookie.set(DISCOVER_WONDER_PAGE, true, {expires: 30});
    }
    window.location.href = "/";
    closeDialog();
  };

  return (
    <Dialog open={true} className="relative" maxWidth="xs">
      <StlDialogContent className="mb-10">
        <div className="text-center mt-15">
          <h2 className="uppercase">
            This page contains links to other sites.
          </h2>
          <p className="font-book mb-20">
            Wonder’s terms of use, cookie management, and privacy policy notice
            do not apply once you leave our site. Wonder is not responsible for
            the content of the next or any other third-party sites.
          </p>

          <StlButtonWrapper className="flex-row mt-15">
            <Button
              size="medium"
              variant="contained"
              className="button-width-style nowrap"
              onClick={backToHomepage}
            >
              Back to Homepage
            </Button>

            <Button
              className="button-width-style  nowrap"
              size="medium"
              variant="contained"
              color="primary"
              onClick={cancelButtonClick}
            >
              Continue
            </Button>
          </StlButtonWrapper>
          <StlFormControlLabel
            control={<Checkbox checked={dialogCookie} color="primary" />}
            label="Don't show this message again"
            onChange={(e, v) => setDialogCookie(v)}
            className="mb-15 mt-15"
          />
        </div>
      </StlDialogContent>
    </Dialog>
  );
}

export default withDialogManager(
  "discover_wonder_page_dialog",
  DiscoverWonderPageDialog
);
