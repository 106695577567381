import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import ShopPageDialog from "../components/Dialog/ShopPageDialog";

let checkedOut = false;

export default function PrismicPage(props) {
  const loadingFunc = props.loading;
  const dispatch = useDispatch();

  setTimeout(() => {
    loadingFunc("yas");
  }, 200);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://api.iheartjane.com/v1/brand_partners/360/embed.js?noScroll=true";
    script.async = true;
    script.id = "jane-frame-script";
    document.getElementById("jane-script-embed").appendChild(script);

    window.addEventListener("message", receiveMessage, false);

    function receiveMessage(event) {
      var payload = event.data && event.data.payload;

      if (!payload || event.data.messageType !== "analyticsEvent") return;

      if (!checkedOut && payload.name === "checkout") {
        var customerEmail =
          payload.properties && payload.properties.customerEmail;
        var subtotal = payload.properties && payload.properties.estimatedTotal;
        var cartId = payload.properties && payload.properties.cartId;
        var products = payload.properties && payload.properties.products;
        // console.log(subtotal, cartId, products);
        // you can use the following code:
        var items = products.map(
          ({
            product_id,
            name,
            brand,
            category,
            kind,
            unit_price,
            count,
            special_id,
            special_title,
          }) => ({
            product_id: product_id,
            name: name,
            brand: brand,
            category: kind,
            variant: category,
            quantity: count,
            price: unit_price,
          })
        );

        window.analytics.track("Order Completed", {
          order_id: cartId,
          affiliation: "Brand Website iFrame",
          subtotal: subtotal,
          currency: "USD",
          products: items,
        });
      }

      if (payload.name === "menuLoad") {
        var customerEmail =
          payload.properties && payload.properties.customerEmail;
        var storeId = payload.properties && payload.properties.storeId;
      }

      if (payload.name === "productView") {
        var productId = payload.properties && payload.properties.productId;
        var productKind = payload.properties && payload.properties.productKind;
        var product = payload.properties && payload.properties.product;

        window.analytics.track("Product Viewed", {
          product_id: productId,
          category: productKind,
          name: product.name,
          brand: product.brand,
        });
      }

      if (payload.name === "cartItemAdd") {
        var productId = payload.properties && payload.properties.productId;
        var product = payload.properties && payload.properties.product;
        window.analytics.track("Product Added", {
          product_id: productId,
          category: productKind,
          name: product.name,
          brand: product.brand,
        });
      }

      if (payload.name === "cartItemRemoval") {
        var productId = payload.properties && payload.properties.productId;
        var product = payload.properties && payload.properties.product;

        window.analytics.track("Product Removed", {
          product_id: productId,
          category: productKind,
          name: product.name,
          brand: product.brand,
        });
      }
    }
  }, []);

  return (
    <div>
      <ShopPageDialog />
      <div className="simple-page" style={{marginTop: "64px"}}>
        <div className="wrapper">
          <div id="jane-script-embed"></div>
        </div>
      </div>
    </div>
  );
}
