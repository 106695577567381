import React, {useState} from "react";
import PrismicRichText from "../components/PrismicRichText";
import MarketingDialog from "../components/MarketingDialog";

export default function RenderNewsletterSection(slice) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const backgroundStyle = {
    background: slice.primary.background_color,
    color: slice.primary.text_color,
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const sectionAlignment = slice.primary.section_alignment;

  return (
    <div>
      {dialogOpen && (
        <MarketingDialog closeDialog={closeDialog}></MarketingDialog>
      )}

      <section className="text-image-section" style={backgroundStyle}>
        <div className="wrapper">
          <div
            className={`flex-wrapper ${
              sectionAlignment === "Image / Content" && "lead-image"
            }`}
          >
            <div className="content-wrapper standard-padding">
              <PrismicRichText render={slice.primary.content} />

              {slice.primary.cta_text[0].text && (
                <div className="button-wrapper">
                  <a
                    href="#sign-up"
                    onClick={() => {
                      setDialogOpen(true);
                    }}
                    className={`button solid-yellow ${slice.primary.cta_alignment}`}
                  >
                    {slice.primary.cta_text[0].text}
                  </a>
                </div>
              )}
            </div>

            <div className="image-wrapper">
              <img src={slice.primary.image.url} alt="Newsletter Sign Up" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
