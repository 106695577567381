import React from "react";
import {RichText} from "prismic-reactjs";
import URLCheckerLink from "../Link/URLCheckerLink";

export default function PrismicRichText({render, ...rest}) {
  return (
    <RichText
      render={render}
      elements={{
        hyperlink: ({children, data}) => {
          const urlDataType = data?.uid ? data?.uid : data?.slug;
          if (data?.uid || data?.slug)
            return (
              <a href={urlDataType} target={data?.target}>
                {children}
              </a>
            );
          return (
            <URLCheckerLink
              target={data?.target}
              href={data?.url}
              url={data?.url}
              children={children}
            />
          );
        },
      }}
      {...rest}
    />
  );
}
