import * as prismic from "@prismicio/client";

const endpoint = prismic.getRepositoryEndpoint("wonder-staging");
const accessToken =
  "MC5YeEhZZkJFQUFDSUF3QjRZ.77-9OS7vv71pWjxVAO-_vTFK77-9Mu-_vUjvv73vv73vv73vv71i77-977-977-977-9Je-_ve-_ve-_ve-_ve-_ve-_vQ"; // Set an access token
const routes = [];

const client = prismic.createClient(endpoint, {routes, accessToken});

export default client;
