import React, {useState} from "react";
import Link from "../components/Link/index.js";
import Collapse from "@material-ui/core/Collapse";
import isValidLink from "../helpers/isValidLink";
import {get} from "lodash";
import PrismicRichText from "../components/PrismicRichText/index.js";

export default function RenderMoodsSection(slice) {
  const backgroundStyle = {
    background: slice.primary.background_color,
  };

  const ctaText = slice.primary.cta_text;
  const ctaLink = slice.primary.cta_link;
  const ctaAlignment = slice.primary.cta_alignment;

  function renderMoods(items) {
    function Mood(el, i) {
      const [open, setOpen] = useState(false);
      const ctaText = el.cta_text;
      const ctaLink = el.cta_link;

      const ctaText2 = el.cta_text_2;
      const ctaLink2 = el.cta_link_2;

      return (
        <div
          className={`mood-item animate ${el.coming_soon && "coming-soon"}`}
          style={{
            background: el.background_color,
            color: el.text_color,
            backgroundImage: `url(${get(el.background_image, "url")})`,
          }}
          key={"mood-item-" + i}
          onClick={() => setOpen(!open)}
        >
          <div className="toggle" onClick={() => setOpen(!open)}>
            <div className="toggle-text">{!open ? "more" : "less"}</div>
            <div className={`toggle-icon ${open && "open"}`}>v</div>
          </div>
          <div
            className="lifestyle-image"
            style={{background: "url(" + el.image_2.url + ")"}}
          ></div>

          <div className="content">
            <div className="text-content">
              <div className="text-content-1">
                <PrismicRichText render={el.content} />
              </div>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <div className="text-content-2">
                  <PrismicRichText render={el.content2} />
                </div>

                {isValidLink(ctaLink, ctaText) && (
                  <div className={"buttons"}>
                    <Link
                      to={"/" + get(el, "cta_link.uid")}
                      url={get(el, "cta_link.url")}
                      target={get(el, "cta_link.target")}
                      className="button"
                      style={{
                        backgroundColor: "white",
                        color: el.background_color,
                      }}
                    >
                      {el.cta_text[0].text}
                    </Link>
                  </div>
                )}

                {isValidLink(ctaLink2, ctaText2) && (
                  <div className={"buttons"}>
                    <Link
                      to={"/" + get(el, "cta_link_2.uid")}
                      url={get(el, "cta_link_2.url")}
                      target={get(el, "cta_link_2.target")}
                      className="button"
                      style={{
                        backgroundColor: "white",
                        color: el.background_color,
                      }}
                    >
                      {el.cta_text_2[0].text}
                    </Link>
                  </div>
                )}
              </Collapse>
            </div>
            <div className="product-image">
              <img src={el.image_1.url} alt="Mood" />
            </div>
          </div>
        </div>
      );
    }

    return items.map((el, i) => Mood(el, i));
  }

  return (
    <section className="moods-section" style={backgroundStyle}>
      <div className="wrapper">
        {slice.primary.content && slice.primary.content[0].text && (
          <div className="standard-padding gray-headlines-and-copy">
            <PrismicRichText render={slice.primary.content} />
            {isValidLink(ctaLink, ctaText) && (
              <div
                className={`buttons ${
                  ctaAlignment === "center-align" && "center-align"
                } ${ctaAlignment === "right-align" && "right-align"}`}
              >
                <Link
                  to={"/" + get(slice, "primary.cta_link.uid")}
                  url={get(slice, "primary.cta_link.url")}
                  target={get(slice, "primary.cta_link.target")}
                  className="button"
                >
                  {ctaText[0].text}
                </Link>
              </div>
            )}
          </div>
        )}
        <div className="moods-wrapper">{renderMoods(slice.items)}</div>
      </div>
    </section>
  );
}
