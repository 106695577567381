import { put, delay } from 'redux-saga/effects';
import { ACCESSIBILITY } from './../../constants/localStorage';

export function* checkAccessibility(api, router, action) {
  const showAccessibility = window.localStorage.getItem(ACCESSIBILITY);

  if (showAccessibility !== null) {
    yield put({
      type: 'ACCESSIBILITY/SET',
      show: showAccessibility === 'true'
    });
    yield put({ type: 'ACCESSIBILITY/ADD' });
  }
}

export function* saveAccessibility(api, router, action) {
  yield put({
    type: 'ACCESSIBILITY/SET',
    show: action.show
  });
  yield put({ type: 'ACCESSIBILITY/ADD' });
  window.localStorage.setItem(ACCESSIBILITY, action.show);

  yield delay(150);

  window.location.reload();
}
