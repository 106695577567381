import React from "react";
import {useDispatch} from "react-redux";
import Cookie from "js-cookie";
import {HIDE_THIRD_PARTY_DIALOG} from "../../constants/cookies";

const URLCheckerLink = ({target, href, onClick, className, children, url}) => {
  const dispatch = useDispatch();

  const openDialog = (thirdPartyURL) => {
    dispatch({
      type: "DIALOG/SHOW",
      id: "third_party_linking_dialog",
      options: {thirdPartyURL},
    });
  };

  const validateURL = (urlString) => {
    try {
      new URL(urlString);
    } catch (error) {
      return false;
    }
    return true;
  };

  if (!validateURL(url)) return null;

  const urlHref = new URL(url).href;
  const urlHostName = new URL(url).hostname;
  const currentHostName = window.location.hostname;
  const showDialogCookie = Cookie.get(HIDE_THIRD_PARTY_DIALOG);

  const linkChecker = (e) => {
    if (!showDialogCookie && currentHostName !== urlHostName) {
      e.preventDefault();
      openDialog(urlHref);
    } else {
      return onClick;
    }
  };

  return (
    <a
      target={target}
      href={href}
      rel="noopener noreferrer"
      onClick={linkChecker}
      className={className}
    >
      {children}
    </a>
  );
};

export default URLCheckerLink;
