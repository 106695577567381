import React from "react";
import RenderNewsletterSection from "./NewsletterSection";
import RenderFormsSection from "./FormsSection";
import RenderMoodsSection from "./MoodsSection";
import RenderTextSection from "./TextSection";
import RenderTextImageSection from "./TextImageSection";
import RenderProductsSection from "./ProductsSection";
import RenderFAQSection from "./FAQSection";
import RenderFullScreenVideoSlice from "./FullScreenVideo";
import PhotoSection from "./PhotoSection";
import ProductGallery from "./ProductGallery";
import PDFSection from "./PDFSection";
import ColumnSlice from "./ColumnSlice";
import IframeSlice from "./IframeSlice";
import MicrocastSlice from "./MicrocastSlice";
import TextImageSectionTwoCopySections from "./TextImageSectionTwoCopySection";

export default function Slices(props) {
  if (!document) {
    return null;
  }
  return props.slices.map((slice, index) => (
    <div key={index}>
      {slice.slice_type === "text_section" && RenderTextSection(slice)}
      {slice.slice_type === "products_section" && RenderProductsSection(slice)}
      {slice.slice_type === "text_image_section" &&
        RenderTextImageSection(slice)}
      {slice.slice_type === "newsletter_slice" &&
        RenderNewsletterSection(slice)}
      {slice.slice_type === "forms_section" && RenderFormsSection(slice)}
      {slice.slice_type === "moods_section" && RenderMoodsSection(slice)}
      {slice.slice_type === "faq_section" && RenderFAQSection(slice)}
      {slice.slice_type === "full_screen_video" &&
        RenderFullScreenVideoSlice(slice)}
      {slice.slice_type === "photo_section" && PhotoSection(slice)}
      {slice.slice_type === "product_gallery" && ProductGallery(slice)}
      {slice.slice_type === "pdf_section" && PDFSection(slice)}
      {slice.slice_type === "column_section" && ColumnSlice(slice)}
      {slice.slice_type === "iframe_slice" && IframeSlice(slice)}
      {slice.slice_type === "microcast_slice" && MicrocastSlice(slice)}
      {slice.slice_type === "text_image_section_two_copy_sections" &&
        TextImageSectionTwoCopySections(slice)}
    </div>
  ));
}
