import React from "react";
import Link from "../components/Link";
import PrismicRichText from "../components/PrismicRichText";
import isValidLink from "../helpers/isValidLink";
import {get} from "lodash";

export default function RenderTextImageSection(slice) {
  const backgroundStyle = {
    background: slice.primary.background_color,
    color: slice.primary.text_color,
  };

  const sectionAlignment = slice.primary.section_alignment;
  const fullWidth = slice.primary.full_width;
  const ctaText = slice.primary.cta_text;
  const ctaLink = slice.primary.cta_link;
  const ctaText2 = slice.primary.cta_text_2;
  const ctaLink2 = slice.primary.cta_link_2;

  return (
    <section
      className="text-image-section gray-headlines-and-copy animate custom-animation"
      style={backgroundStyle}
    >
      <div className={`wrapper ${fullWidth === "Yes" && "full-width"}`}>
        <div
          className={`flex-wrapper ${
            sectionAlignment === "Image / Content" && "lead-image"
          }`}
        >
          <div className="content-wrapper standard-padding">
            <div className="content-wrapper-inner">
              <PrismicRichText render={slice.primary.content} />

              {isValidLink(ctaLink, ctaText) && (
                <div
                  className={`button-wrapper ${slice.primary.cta_alignment}`}
                >
                  <Link
                    to={"/" + get(slice, "primary.cta_link.uid")}
                    url={get(slice, "primary.cta_link.url")}
                    target={get(slice, "primary.cta_link.target")}
                    className={`button solid-black`}
                  >
                    {slice.primary.cta_text[0].text}
                  </Link>
                </div>
              )}

              {isValidLink(ctaLink2, ctaText2) && (
                <div
                  className={`button-wrapper ${slice.primary.cta_alignment}`}
                >
                  <Link
                    to={"/" + get(slice, "primary.cta_link_2.uid")}
                    url={get(slice, "primary.cta_link2.url")}
                    target={get(slice, "primary.cta_link2.target")}
                    className={`button solid-black`}
                  >
                    {slice.primary.cta_text_2[0].text}
                  </Link>
                </div>
              )}
            </div>
          </div>

          {slice.primary.image.url && (
            <div className="image-wrapper">
              <img src={slice.primary.image.url} alt="Featured" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
