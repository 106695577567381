const defaultState = {};

export default function prismic(state = defaultState, action) {
  switch (action.type) {
    case 'PRISMIC_FETCHING':
      return {
        ...state,
        [action.key]: { ...state[action.key], loading: true, error: undefined }
      };

    case 'PRISMIC_FETCH_SUCCESS':
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          data: action.data,
          loading: false,
          error: undefined
        }
      };

    case 'PRISMIC_FETCH_ERROR':
      return {
        ...state,
        [action.key]: { ...state[action.key], loading: false, error: action.error }
      };
    default:
      return state;
  }
}
