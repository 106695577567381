import React from "react";
import PrismicRichText from "../components/PrismicRichText";

export default function RenderFullScreenVideoSlice(slice) {
  const backgroundVideo = slice.primary.background_video.url;

  const sliceContent = slice.primary.content;
  const sliceDisclaimers = slice.primary.disclaimers;
  const sliceLogo = slice.primary.logo.url;

  const overlayColor = slice.primary.overlay_color[0].text;

  const overlayStyle = {
    background: overlayColor,
  };

  return (
    <section className="full-screen-video-section">
      <video autoPlay playsInline muted loop>
        <source src={backgroundVideo} type="video/mp4" />
      </video>

      <div className="overlay" style={overlayStyle}></div>

      <div className="logo-content">
        <div className="video-logo">
          <img src={sliceLogo} alt="Logo" />
        </div>

        <div className="content">
          <PrismicRichText render={sliceContent} />
        </div>
      </div>

      <div className="disclaimers">
        <PrismicRichText render={sliceDisclaimers} />
      </div>
    </section>
  );
}
