import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import {get} from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import Link from "../components/Link";

export default function MicrocastSlice(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("600"));

  const bgImage = isMobile
    ? get(slice, "primary.background_image.Mobile.url")
    : get(slice, "primary.background_image.url");

  const bgstyle = {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
  };

  const renderButton = (buttons) => {
    return buttons.map((el, i) => (
      <Link
        key={i}
        to={"/" + get(el, "cta_url.uid")}
        url={get(el, "cta_url.url")}
        target={get(el, "cta_url.target")}
        className="special-button"
      >
        <img src={get(el, "button.url")} alt="button" />
      </Link>
    ));
  };

  return (
    <div className="microcast-slice" style={bgstyle}>
      <div className="wrapper">
        <div className="content">
          <PrismicRichText render={slice.primary.content} />
          <div className="button-container">
            {renderButton(get(slice, "items"))}
          </div>
        </div>
      </div>
    </div>
  );
}
