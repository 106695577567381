import React from "react";
import {Link} from "prismic-reactjs";
import linkResolver from "../../helpers/linkResolver";
import isValidLink from "../../helpers/isValidLink";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PrismicRichText from "../PrismicRichText";

const CustomCarousel = (props) => {
  return (
    <Carousel>
      {props.items.map((el, i) => (
        <div
          key={i}
          style={{backgroundColor: el.background_color}}
          className={el.background_color && "solid-background"}
        >
          <img
            src={
              el.product_photo !== undefined
                ? el.product_photo.url
                : el.image.url
            }
            alt="Product"
          />

          <div className="rich-text center-align">
            <PrismicRichText render={el.rich_content} />
          </div>

          {isValidLink(el.cta_link, el.cta_text) && (
            <div className="center-align">
              <a href={Link.url(el.cta_link, linkResolver)} className="button">
                {el.cta_text[0].text}
              </a>
            </div>
          )}
        </div>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
